@use "sass:meta";
@use "theme/typo" as *;

/* SVNL CSS for Ionic */
@import '@svnl/theme/styles/ionic.scss';

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

:root {
  --ion-item-color: var(--ion-color-primary);

  ion-content {
    // TODO: remove box-shadow, need to fix settings page
    ion-card {
      box-shadow: none;
    }

    // TODO: move ion-text to shared theme?
    ion-text {
      font-size: map-get(map-get($svnl-typography, 'body'), 'font-size');
    }
  }

  // TODO: move h1 to shared theme?
  h1 {
    font-size: map-get(map-get($svnl-typography, 'header-1'), 'font-size');
    font-weight: map-get(map-get($svnl-typography, 'header-1'), 'font-weight');
    line-height: map-get(map-get($svnl-typography, 'header-1'), 'line-height');
    color: var(--ion-color-tertiary);
  }

  // TODO: move h2 to shared theme?
  h2 {
    font-size: map-get(map-get($svnl-typography, 'header-2'), 'font-size');
    font-weight: map-get(map-get($svnl-typography, 'header-2'), 'font-weight');
    color: var(--ion-color-primary);
  }

  // TODO: move h3 to shared theme?
  h3 {
    font-size: map-get(map-get($svnl-typography, 'header-3'), 'font-size');
    font-weight: map-get(map-get($svnl-typography, 'header-3'), 'font-weight');
    color: var(--ion-color-primary);
  }

  // TODO: move h4 to shared theme?
  h4 {
    font-size: map-get(map-get($svnl-typography, 'header-4'), 'font-size');
    font-weight: map-get(map-get($svnl-typography, 'header-4'), 'font-weight');;
  }

  // TODO: move svnl-caption to shared theme?
  .svnl-caption {
    font-size: map-get(map-get($svnl-typography, 'caption'), 'font-size');
  }

  // TODO: remove pointer, need to fix settings page
  .pointer {
    cursor: pointer
  }

  @media screen and (min-width: 768px) {
    .page-container {
      max-width: 32rem;
      margin: 2rem auto;
      border-radius: 0.25rem;
      padding: 1rem;
      background-color: white;
      min-height: 80%;
    }
  }
}

/** ESRI SPECIFIC **/
.esri-component.esri-attribution.esri-widget {
  display: none;
}
